import React from 'react';
import { FaPhone } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';

const Contact = () => {
  return (
    <section id="contact" className="contact"> 
        <div className="row mb0">
            <div className="col s12 left-align"> 
                <ScrollAnimation animateIn="fadeIn">
                    <h3 className="white-text">Contact Us</h3>
                </ScrollAnimation>
            </div>
            <div className="col s12 left-align">
                <ScrollAnimation animateIn="fadeIn">           
                    <p className="white-text left-align mb0">Greenwood Development, LLC <br />131 Glenridge Avenue<br />Montclair, NJ 07042</p>
                </ScrollAnimation>
            </div>
            <div className="col s12 left-align">
                <ScrollAnimation animateIn="fadeIn"> 
                    <ul className="icon mt0">
                        <li>          
                            <p className="left-align"><a href="tel:973-552-8184" className="white-text"><i className="white-text icon"><FaPhone /></i></a></p>
                        </li>         
                        <li>
                            <p className="left-align"><a href="mailto:Email: darla.petronio1@gmail.com" className="white-text icon"><i className="white-text icon"><FaEnvelope /></i></a></p>
                        </li>
                    </ul> 
                </ScrollAnimation>
            </div>
            <div className="col s1 center-align hide">
                <ScrollAnimation animateIn="fadeIn">           
                    <p className="black-text left-align"><a href="tel:973-552-8184" className="white-text"><i className="white-text icon"><FaPhone /></i></a></p>
                </ScrollAnimation>
            </div>
            <div className="col s11 left-align hide">
                <ScrollAnimation animateIn="fadeIn">           
                    <p className="white-text left-align"><a href="mailto:Email: darla.petronio1@gmail.com" className="white-text icon"><i className="white-text icon"><FaEnvelope /></i></a></p>
                </ScrollAnimation>
            </div>
        </div>
    </section>
  )
}
export default Contact;
