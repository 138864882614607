import React from 'react';

const Header = () => {
	return (

    <section className="header black">

		<div className="row valign-wrapper mb0 hide-ipad-up">
        	<div className="col s12 l5 left-align">
        		<div className="row left-section mb0">
          			<div className="col s12 left-align">
          				<h1 className="white-text uppercase mb0 mt0">Investing Locally,<br />Thinking Globally.</h1>  
          			</div>
	          		<div className="col s12 left-align hide-ipad-up">
	          			<br />
	            		<a href="tel:973-552-8184-" className="waves-effect waves-light btn brand-btn transparent uppercase">Contact Us</a>
	          		</div>
        		</div>
        	</div>
        	<div className="col s12 l7 left-align bg-header"></div>
    	</div>  

		<div className="container hide-ipad-down">
        	<div className="row mb0">
        		<div className="col s12 left-align bg-header valign-wrapper">
					<div className="col s12 left-align">
		      			<h1 className="white-text uppercase mb0 mt0">Investing Locally,<br /> Thinking Globally.</h1>  
			          	<br />
			            <a href="tel:973-552-8184" className="waves-effect waves-light btn brand-btn transparent uppercase">Contact Us</a>
	        		</div>
				</div>
    		</div>  
    	</div>    

    </section>
	)
}
export default Header;