import React from 'react';
import './App.css';
import 'materialize-css/dist/css/materialize.css';
import 'materialize-css/dist/js/materialize.js';
import Nav from './components/Nav';
import Header from './components/Header';
import About from './components/About';
import Properties from './components/Properties';
// import Prop2 from './components/Prop2';
import Contact from './components/Contact';
import Footer from './components/Footer';



function App() {
  return (
    <div className="App">
        <Nav />
        <Header />
        <About />
        <Properties />
        <Contact />
        <Footer />
    </div>
  );
}

export default App;
