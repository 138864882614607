import React from 'react';
import { FaPhone } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
    return (

    <footer className="page-footer black">
        <div className="row hide">
            <div className="col s12">
                <ul className="left-align">
                    <li>
                        <a href="tel:973-000-0000" className=""><i className="material-icons white-text"><FaLinkedinIn /></i></a>
                    </li>
                    <li>
                        <a href="tel:973-000-0000" className=""><i className="circle-icon white-text"><FaPhone /></i></a>
                    </li>
                    <li>
                        <a href="mailto:Email: email@greenwooddevelopmentnj.com" className=""><i className="material-icons white-text"><FaEnvelope /></i></a>
                    </li>
                </ul>
            </div>
        </div>
        
        <div className="footer-copyright">
            © GREENWOOD DEVELOPMENT, LLC 2020 | All Rights Reserved     
        </div>
    </footer>

    )
}
export default Footer;