import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'materialize-css/dist/css/materialize.min.css'
import ScrollAnimation from 'react-animate-on-scroll';
window.jQuery = window.$ = $;

class Vestry extends Component {

    componentDidMount() {

    }

    render() {

        return (
                   
        <section id="properties" className="properties valign-wrapper"> 
            <div className="row mb0">
                
                <div className="col s12 left-align">           
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --odd">
                                            <img className="img-shadow responsive-img" alt="The Vestry Exterior" src="vestry_11.jpg"/>
                                        </div>
                                        <div className="text-wrapper valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">Lorem ipsum dolor sit amet, consectetur adipiscing.<br />+ 46 Luxury Rental Apartments<br />+ 1,600 Sq Ft of Retail Space</p>
                                            </div>
                                        </div> 
                                    </ScrollAnimation>                                  
                                    </div>
                                    <p className="caption white-text mt0">The Vestry - Montclair, NJ</p>
                                </div>
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --even">
                                            <img className="img-shadow responsive-img" alt="The Vestry Interior" src="vestry_2.png"/>
                                        </div>
                                        <div className="text-wrapper -even valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod reprehenderit in voluptate.</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>                                   
                                    </div>
                                    <p className="caption white-text mt0">The Vestry - Montclair, NJ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                </div>
     
            </div>
        </section>          

        );
    }
}
export default Vestry;