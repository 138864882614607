import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const About = () => {
  return (
    <section id="about" className="about"> 
        <div className="row">
            <div className="col s12 l8 left-align"> 
                <ScrollAnimation animateIn="fadeIn">
                    <h3 className="white-text">About</h3>
                </ScrollAnimation>
            </div>
            <div className="col s12 m6 l7 left-align hide-on-small-and-down"> 
                <ScrollAnimation animateIn="fadeIn">          
                    <p className="white-text left-align m0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </ScrollAnimation>
            </div>
            <div className="col s12 m6 l5 left-align hide-on-small-and-down">
                <ScrollAnimation animateIn="fadeIn">           
                    <img className="responsive-img" alt="David Genova" src="header.jpg"/>
                    <p className="white-text left-align">David Genova</p>
                </ScrollAnimation>
            </div>
            <div className="col s12 m6 l4 left-align hide-on-med-and-up">  
                <ScrollAnimation animateIn="fadeIn">         
                    <img className="responsive-img" alt="David Genova" src="header.jpg"/>
                    <p className="white-text left-align">David Genova</p>
                </ScrollAnimation>
            </div>
            <div className="col s12 m6 l8 left-align hide-on-med-and-up">   
                <ScrollAnimation animateIn="fadeIn">        
                    <p className="white-text left-align mt0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </ScrollAnimation>
            </div>
        </div>
    </section>
  )
}
export default About;
