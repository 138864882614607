import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'materialize-css/dist/css/materialize.min.css'
// import M from 'materialize-css'
import ScrollAnimation from 'react-animate-on-scroll';
window.jQuery = window.$ = $;

class Wellmont extends Component {

    componentDidMount() {
        // document.addEventListener('DOMContentLoaded', function() {
        //     var elems = document.querySelectorAll('.modal');
        //     var instances = M.Modal.init(elems);
        // });
        // $(document).ready(function(){
        //     $('.modal').modal();
        // });
        // $(".sidenav").click(function(){
        //     $(".sidenav a").hide();
        //     console.log('true');
        // });
    }

    render() {

        return (
                   
        <section id="properties" className="properties valign-wrapper"> 
            <div className="row mb0">
                
                <div className="col s12 left-align">           
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --odd">
                                            <img className="img-shadow responsive-img" alt="The Wellmont Exterior" src="wellmont_1.png"/>
                                        </div>
                                        <div className="text-wrapper valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">In a joint venture with Brookfield Properties & The Pinnacle Companies, Greenwood Development purchased the Wellmont Theater in 2015.</p>
                                            </div>
                                        </div> 
                                    </ScrollAnimation>                                  
                                    </div>
                                    <p className="caption white-text mt0">The Wellmont Theater - Montclair, NJ</p>
                                </div>
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --even">
                                            <img className="img-shadow responsive-img" alt="The Wellmont Interior" src="wellmont_2.png"/>
                                        </div>
                                        <div className="text-wrapper -even valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">The Wellmont hosts nationally touring musical & comedy acts and is the region's premiere entertainment venue.</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>                                   
                                    </div>
                                    <p className="caption white-text mt0">The Wellmont Theater - Montclair, NJ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className="col s12 left-align">           
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --odd">
                                            <img className="img-shadow responsive-img" alt="The Wellmont" src="wellmont_3.png"/>
                                        </div>
                                        <div className="text-wrapper valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">I can add additional text/details here</p>
                                            </div>
                                        </div> 
                                    </ScrollAnimation>                                  
                                    </div>
                                    <p className="caption white-text mt0">The Wellmont Theater - Montclair, NJ</p>
                                </div>
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --even">
                                            <img className="img-shadow responsive-img" alt="The Wellmont" src="wellmont_42.png"/>
                                        </div>
                                        <div className="text-wrapper -even valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">Additional Text</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>                                   
                                    </div>
                                    <p className="caption white-text mt0">The Wellmont Theater - Montclair, NJ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                </div>  

            </div>
        </section>          

        );
    }
}
export default Wellmont;