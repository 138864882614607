import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'materialize-css/dist/css/materialize.min.css'
// import M from 'materialize-css'
import ScrollAnimation from 'react-animate-on-scroll';
window.jQuery = window.$ = $;

class Park extends Component {

    componentDidMount() {
    }

    render() {

        return (
                   
        <section id="properties" className="properties valign-wrapper"> 
            <div className="row mb0">
                
                <div className="col s12 left-align">           
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --odd">
                                            <img className="img-shadow responsive-img" alt="66 Park" src="park_22.jpg"/>
                                        </div>
                                        <div className="text-wrapper valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">In a joint venture with The Becker Organization, and Saxum Real Estate, Greenwood Development purchased this 15,500 sf multi-tenant office building in 2018.</p>
                                            </div>
                                        </div> 
                                    </ScrollAnimation>                                  
                                    </div>
                                    <p className="caption white-text mt0">66 Park - Montclair, NJ</p>
                                </div>
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --even">
                                            <img className="img-shadow responsive-img" alt="66 Park" src="park_22.jpg"/>
                                        </div>
                                        <div className="text-wrapper -even valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">The building's common core and restrooms received a full gut renovation along with major improvements to the facade and landscaping.</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>                                   
                                    </div>
                                    <p className="caption white-text mt0">66 Park - Montclair, NJ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="divider"></div>
                </div>
     
            </div>

        </section>          

        );
    }
}
export default Park;