import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'materialize-css/dist/css/materialize.min.css'
// import M from 'materialize-css'
import ScrollAnimation from 'react-animate-on-scroll';
window.jQuery = window.$ = $;

class Valley extends Component {

    componentDidMount() {

    }

    render() {

        return (
                   
        <section id="properties" className="properties valign-wrapper"> 
            <div className="row mb0">
                
                <div className="col s12 left-align">           
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --odd">
                                            <img className="img-shadow responsive-img" alt="89 Valley Exterior" src="valley_1.png"/>
                                        </div>
                                        <div className="text-wrapper valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text hide-on-med-and-down">In a joint venture with The Becker Organization, Greenwood Development purchased this aging medical building in 2017. After facade upgrades and a full gut renovation of the interior, this building has been repositioned and is now fully leased as a plastic surgery and skincare center.<br />+ 10,000 sq ft Medical Office Building</p>
                                                <p className="white-text hide-on-med-and-up">In a joint venture with The Becker Organization, Greenwood Development purchased this aging medical building in 2017.</p>
                                            </div>
                                        </div> 
                                    </ScrollAnimation>                                  
                                    </div>
                                    <p className="caption white-text mt0">89 Valley - Montclair, NJ</p>
                                </div>
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --even">
                                            <img className="img-shadow responsive-img" alt="89 Valley Exterior" src="valley_22.png"/>
                                        </div>
                                        <div className="text-wrapper -even valign-wrapper">
                                            <div className="container">    
                                                <p className="white-text hide-on-med-and-down">+ 10,000 sq ft Medical Office Building</p>
                                                <p className="white-text hide-on-med-and-up">After facade upgrades and a full gut renovation of the interior, this building has been repositioned and is now fully leased as a plastic surgery and skincare center.<br /><br />+ 10,000 sq ft Medical Office Building</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>                                   
                                    </div>
                                    <p className="caption white-text mt0">89 Valley - Montclair, NJ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="divider"></div>
                </div>
     
            </div>

        </section>          

        );
    }
}
export default Valley;