import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery';
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css'
// import Header from './Header';
import { FaBars } from 'react-icons/fa';
window.jQuery = window.$ = $;

class Nav extends Component {

    componentDidMount() {
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems);
        });

        // document.getElementById('mobile-link').addEventListener('click',function(e){
        //     document.getElementById('mobile-demo').style.display = 'none';
        //     console.log('true');
        // });

            // $(".sidenav").click(function(){
            //     $(".sidenav a").hide();
            //     console.log('true');
            // });

            //   $(".sidenav a").click(function(){
            //     $(".sidenav a").hide();
            //     console.log('true');
            // });

        // });
    }

    render() {

        return (

            <Router className="">
                <div className="black z-depth-0">
                    <nav className="black z-depth-0">
                        <div className="nav-wrapper transparent">
                            <Link className="left brand-logo" to="/"><img className="logo responsive-img" alt="Greenwood Development" src="logo.png"/></Link>
                            <ul className="right hide-on-med-and-down">
                                <li><Link to="/#about" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>About</Link></li>
                                <li><Link to="/#properties" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Properties</Link></li> 
                                <li><Link to="/#contact" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Contact</Link></li> 
                            </ul>

                            <Link to="#" data-target="mobile-demo" className="sidenav-trigger right hide"><i className="material-icons right"><i className="material-icons white-text"><FaBars /></i></i></Link>
                            <Link to="#" data-target="mobile-demo" className="sidenav-trigger right">
                                <div className="menu-icon right">
                                    <span className="line top"></span>
                                    <span className="line middle"></span>
                                </div>
                            </Link>
                            <ul className="sidenav black" id="mobile-demo">                                
                                <li id="mobile-link"><Link className="sidenav-close white-text" to="/#about">About</Link></li>
                                <li id="mobile-link"><Link className="sidenav-close white-text" to="/#properties">Properties</Link></li>                           
                                <li id="mobile-link"><Link className="sidenav-close white-text" to="/#contact">Contact</Link></li>                  
                            </ul>
                            
                        </div>
                    </nav>
                </div>
            </Router>
        );
    }
}

export default Nav;