import React from "react";
import Clubhouse from './Properties/Clubhouse';
import Vestry from './Properties/Vestry';
import Wellmont from './Properties/Wellmont';
import PostOffice from './Properties/PostOffice';
import Valley from './Properties/Valley';
import Park from './Properties/Park';

const Properties = () => (

    <div className="properties-container">      
        <Clubhouse />
        <Vestry />
        <Wellmont />
        <Valley />
        <Park />
        <PostOffice />
    </div>

);

export default Properties;