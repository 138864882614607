import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'materialize-css/dist/css/materialize.min.css'
// import M from 'materialize-css'
import ScrollAnimation from 'react-animate-on-scroll';
window.jQuery = window.$ = $;

class Clubhouse extends Component {

    componentDidMount() {
        // document.addEventListener('DOMContentLoaded', function() {
        //     var elems = document.querySelectorAll('.modal');
        //     var instances = M.Modal.init(elems);
        // });
        // $(document).ready(function(){
        //     $('.modal').modal();
        // });
        // $(".sidenav").click(function(){
        //     $(".sidenav a").hide();
        //     console.log('true');
        // });
    }

    render() {

        return (
                   
        <section id="properties" className="properties"> 
            <div className="row mb0">
                
                 <div className="col s12 l9 left-align relative"> 
                     <ScrollAnimation animateIn="fadeIn">
                        <h3 className="white-text">Featured Properties</h3>
                        <p className="white-text left-align">Lorem ipsum dolor sit amet, consectetur adipiscing officia deserunt mollit anim id est laborum.</p>
                        <br /><br />
                    </ScrollAnimation> 
                </div>
                <div className="col s12 left-align">           
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --odd">
                                            <img className="img-shadow responsive-img" alt="The Clubhouse Exterior" src="clubhouse_1.png"/>
                                        </div>
                                        <div className="text-wrapper valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">The Clubhouse is Montclair's premiere event space. At 3,500 square feet, the Clubhouse hosts events of all types and sizes.</p>
                                                <p className="white-text hide">The Clubhouse is Montclair's premiere event space. At 3,500 square feet, the Clubhouse hosts events of all types and sizes.<br className="hide" />+ Private Club<br />+ 3,500 +/- Sq Ft</p>
                                            </div>
                                        </div> 
                                    </ScrollAnimation>                                  
                                    </div>
                                    <p className="caption white-text mt0">The Clubhouse - Montclair, NJ</p>
                                </div>
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --even">
                                            <img className="img-shadow responsive-img" alt="The Clubhouse Interior" src="clubhouse_2.png"/>
                                        </div>
                                        <div className="text-wrapper -even valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">For inquiries, please visit:<br />
                                                <a href="https://www.njeventspace.com" target="_blank" rel="noopener noreferrer" className="white-text">njeventspace.com</a></p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>                                   
                                    </div>
                                    <p className="caption white-text mt0">The Clubhouse - Montclair, NJ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                </div>
                <div className="col s12 left-align hide">           
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --odd">
                                            <img className="img-shadow responsive-img" alt="The Clubhouse Exterior" src="vestry_1.jpg"/>
                                        </div>
                                        <div className="text-wrapper valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">Lorem ipsum dolor sit amet, consectetur adipiscing.<br />+ Private Club<br />+ 3,500 +/- Sq Ft</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>                                    
                                    </div>
                                    <p className="caption black-text mt0">The Vestry - Montclair, NJ <br />46 Luxury Rental Apartments<br />1,600 Sq Ft of Retail Space</p>
                                </div>
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="flex-container--col">
                                <div className="flex-item--col">
                                    <div className="padding-block hide-on-small-and-down"></div>
                                </div>
                                <div className="flex-item--col">
                                    <div className="wrapper">
                                    <ScrollAnimation animateIn="fadeIn">
                                        <div className="green-wrapper --even">
                                            <img className="img-shadow responsive-img" alt="The Clubhouse Interior" src="vestry_2.jpg"/>
                                        </div>
                                        <div className="text-wrapper -even valign-wrapper">
                                            <div className="container">                                
                                                <p className="white-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla.</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>                                  
                                    </div>
                                    <p className="caption black-text mt0">The Clubhouse - Montclair, NJ<br />Interior</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
     
            </div>
        </section>          

        );
    }
}
export default Clubhouse;